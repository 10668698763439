<template>
  <section>
    <!--工具条-->
    <el-col :span="4" class="toolbar roles">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>角色</span>
          <el-button
            @click="getRolePageData"
            style="float: right; padding: 3px 0"
            type="text"
            >刷新</el-button
          >
        </div>
        <div
          v-for="o in roleData"
          :key="o.Id"
          @click="handleRoleSelectChange(o.Id, o.Name)"
          :class="o.Id == currentRoleId ? 'active' : ''"
          class="text item role-item"
        >
          {{ o.Name }}
        </div>
        <!-- 分页器 -->
        <el-pagination
          @current-change="handleRolePageChange"
          :current-page="roleCurrentPage"
          :page-size="rolePageSize" small=""
          layout="prev, pager, next"
          :page-count="roleTotalPage"
          :total="roleTotalData"
        ></el-pagination>
      </el-card>
    </el-col>
    <el-col :span="19" class="toolbar perms">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>权限</span>
          <el-button
            @click="handleSaveAssign"
            style="float: right; padding: 3px 0"
            type="text"
            >保存</el-button
          >
        </div>
        <div class="block">
          <el-tree
            :data="treeData"
            show-checkbox
            node-key="id"
            ref="tree1"
            default-expand-all
            :expand-on-click-node="true"
            :check-strictly="true"
            :indent="30"
            @check-change="handleMenuCheckChange"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <span>{{ data.name }}</span>
              <span>
                <div class="checkAss">
                  <el-checkbox-group v-model="AssignKeys.ButtonKeys">
                    <el-tooltip
                      v-for="btn in data.buttons"
                      :key="btn.id"
                      effect="light"
                      :content="btn.desc"
                      placement="top"
                      :disabled="btn.desc == '' || btn.desc == null"
                    >
                      <el-checkbox
                        :label="btn.id"
                        @change="(checked) => buttonCheckChange(checked, btn)"
                        >{{ btn.name }}</el-checkbox
                      >
                    </el-tooltip>
                  </el-checkbox-group>
                </div>
              </span>
            </span>
          </el-tree>
        </div>
      </el-card>
    </el-col>
  </section>
</template>

<script>
import {
  getRoleListPage,
  getPermissionTree,
  getAssignKeys,
  setAssignKeys,
} from "../../api/hr";

export default {
  data() {
    // const data = [];
    return {
      roleCurrentPage: 1, // 当前页码
      roleTotalPage: null, // 总页数
      roleTotalData: null, //总条数
      rolePageSize: 15, // 每页的数据条数
      roleData: [], //角色数据
      treeData: [], //树数据
      AssignKeys: {
        //分配的权限
        RoleId: null,
        ApplicationId: null,
        ApplicationKeys: [],
        ModuleKeys: [],
        MenuKeys: [],
        ButtonKeys: [],
      },
      currentRoleId: null, //当前选中角色
    };
  },
  methods: {
    //获取角色列表
    getRolePageData() {
      let para = {
        page: this.roleCurrentPage,
        size: this.rolePageSize,
      };
      getRoleListPage(para).then((res) => {
        this.roleTotalPage = res.data.response.pageCount;
        this.roleTotalData = res.data.response.dataCount;
        this.roleData = res.data.response.data;
        this.getPermissionTreeData();
      });
    },
    //监听角色分页器页码改变事件
    handleRolePageChange(val) {
      this.roleCurrentPage = val;
      this.getRolePageData();
    },
    //获取菜单树
    getPermissionTreeData() {
      let _this = this;
      let para = { appId: global.appId };
      getPermissionTree(para).then((res) => {
        let arr = new Array();
        arr.push(res.data.response);
        this.treeData = arr;
        this.resetTreeData(this.treeData);
      });
    },
    //整理数据
    resetTreeData(data) {
      data.forEach((node) => {
        if (node.children && 0 < node.children.length) {
          node.buttons = node.children.filter((item) => {
            return item.type == 3;
          });
          node.children = node.children.filter((item) => {
            return item.type != 3;
          });
          this.resetTreeData(node.children);
        }
      });
    },
    //角色选择改变监听
    handleRoleSelectChange(rid, name) {
      this.currentRoleId = rid;
      let para = { appId: global.appId, rid: rid };
      getAssignKeys(para).then((res) => {
        this.AssignKeys = res.data.response;
        this.$refs.tree1.setCheckedKeys(
          this.AssignKeys.ApplicationKeys.concat(
            this.AssignKeys.ModuleKeys
          ).concat(this.AssignKeys.MenuKeys)
        );
      });
    },
    //权限保存监听
    handleSaveAssign() {
      this.AssignKeys.ApplicationKeys = [];
      this.AssignKeys.ModuleKeys = [];
      this.AssignKeys.MenuKeys = [];
      let mids = this.$refs.tree1.getCheckedKeys();
      mids.forEach((id) => {
        let node = this.$refs.tree1.getNode(id);
        if (node.data.id) {
          if (node.data.type == 0) {
            this.AssignKeys.ApplicationKeys.push(node.data.id);
          } else if (node.data.type == 1) {
            this.AssignKeys.ModuleKeys.push(node.data.id);
          } else if (node.data.type == 2) {
            this.AssignKeys.MenuKeys.push(node.data.id);
          }
        }
      });
      setAssignKeys(this.AssignKeys).then((res) => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 树节点选择监听
    handleMenuCheckChange(data, check, subCheck) {
      if (check) {
        if (data.pid) {
          this.$refs.tree1.setChecked(data.pid, true, false);
        }
      } else {
        if (data.children != null) {
          data.children.forEach((element) => {
            this.$refs.tree1.setChecked(element.id, false, false);
          });
        }
      }
    },
    //按钮选择监听
    buttonCheckChange(check, btn) {
      if (check && btn.pid) {
        this.$refs.tree1.setChecked(btn.pid, true, false);
      }
    },
    // 递归全选
    checkAllMenu(menuData, allMenus) {
      menuData.forEach((menu) => {
        allMenus.push(menu);
        if (menu.children) {
          this.checkAllMenu(menu.children, allMenus);
        }
      });
    },
  },
  mounted() {
    this.getRolePageData();
  },
};
</script>

<style scoped>
.el-col
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

.el-col >>> .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-col >>> .el-icon-caret-right:before {
  content: url("../../assets/add.png") !important;
  font-size: 16px;
}
.el-col >>> .expanded:before {
  content: url("../../assets/cancel.png") !important;
  font-size: 16px;
}
.el-col >>> .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: url("../../assets/blank.png") !important;
  font-size: 16px;
}

.role-item {
  cursor: pointer;
  padding: 10px;
}

.role-item.active {
  background: #fabe64;
}

.role-item:hover {
  background: #ebf5ff;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
  /* height: 1000px; */
  height: auto;
}

.el-checkbox + .el-checkbox {
  margin-left: 5px !important;
}

.el-checkbox {
  margin-right: 5px !important;
}
.checkAss {
  display: inline-grid;
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
  margin-left: 20px;
}
</style>
